import { useEffect, useState } from 'react';
import Select from '../../../components/Select';
import {
  availableQuarters,
  availableYears,
  formatDate,
  getCurrentQuarter,
  getQuartersByYear,
  initialFilters
} from '../../../utils/utils';
import { useForm } from '../../../hooks/useForm';
import {
  useGetWeekEndsQuery
} from '../../../store/slices/areaPoints/apis/areaPointsApi';

export const Filters = (props) => {

  /* States for filters */
  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [weekEndings, setWeekEndings] = useState([]);
  const [initialFilter, setInitialFilter] = useState({});
  const [skipRequestWeekends, setSkipRequestWeekends] = useState(true); // To prevent unnecessary API calls of the weekends
  const [quartersByYear, setQuartersByYear] = useState([]);

  /**
   * UseEffect to start the state of the filters
   */
  useEffect(() => {
    if (years.length === 0 && quarters.length === 0) {
      getAvailableYears();
      getAvailableQuarters();
      setSkipRequestWeekends(false);
    }
  }, [years, quarters]);

  /**
   * Gets years for the "year" combo
   */
  const getAvailableYears = async () => {
    const currentYears = await availableYears().map((year) => ({
      value: year, label: year
    }));
    currentYears.unshift({ value: '', label: '' });
    setYears(currentYears);
  }

  /**
   * Gets quarters by year for the "quarters" combo
   */
  const getAvailableQuarters = () => {
    const currentQuarters = availableQuarters().map((item) => ({
      value: item, label: item
    }));
    currentQuarters.unshift({ value: '', label: '' });
    setQuarters(currentQuarters);
  }

  /**
   * Set default startup filters (current year and quarter)
   */
  const {
    selectedYear,
    selectedQuarter,
    selectedWeekEnding,
    formState,
    onInputChange: handleSelect,
    onSetForm: onSetFilterForm
  } = useForm({
    selectedYear: new Date().getFullYear(),
    selectedQuarter: getCurrentQuarter(),
    selectedWeekEnding: ''
  });

  /**
   * For cleans all filters
   */
  const clearFilters = () => {
    setSkipRequestWeekends(true);
    onSetFilterForm({
      selectedQuarter: '',
      selectedYear: '',
      selectedWeekEnding: ''
    });
    setInitialFilter({
      from: '',
      to: ''
    });
    setQuarters([]);
    setWeekEndings([]);
    setQuartersByYear([]);
  }

  useEffect(() => {
    // Load the rest of the filters, always starting with year
    if (selectedYear) {
      // Fill quarter and weekend combos according to the selected year
      if (selectedYear && !selectedQuarter) {
        getAvailableQuarters();
        handleYearsFilters(selectedYear);
        setSkipRequestWeekends(false);
      }

      // Gets the quarters and their date ranges
      const updatedQuarters = getQuartersByYear(selectedYear);
      setQuartersByYear(updatedQuarters);
    } else {
      // If no year is selected, no quarters or weekends will be displayed
      clearFilters();
    }
  // eslint-disable-next-line
  }, [selectedYear]);

  useEffect(() => {
    if (selectedQuarter) {
      // If year or quarter is updated, gets weekends by the selected options
      if (selectedWeekEnding) {
        onSetFilterForm({
          ...formState,
          selectedWeekEnding: ''
        });
        handleQuartersFilters(selectedQuarter);
        setSkipRequestWeekends(false);
        return;
      }

      // If only weekend is not selected, gets weekends by the quarter selected
      if (!selectedWeekEnding) {
        handleQuartersFilters(selectedQuarter);
        setSkipRequestWeekends(false);
        return;
      }
    } else {
      // If quarter is not selected and weekend is updated, gets weekends by the year selected
      if (selectedWeekEnding) {
        onSetFilterForm({
          ...formState,
          selectedWeekEnding: ''
        });
        handleYearsFilters(selectedYear);
        setSkipRequestWeekends(false);
        return;
      }

      if (selectedYear && !selectedWeekEnding) {
        handleYearsFilters(selectedYear);
        return;
      }
    }
  // eslint-disable-next-line
  }, [selectedQuarter, quartersByYear]);

  /**
   * Gets range for FROM-TO label when only year is selected
   * @param {*} yearValue 
   */
  const handleYearsFilters = async (yearValue) => {
    const filtersLabel = await initialFilters(yearValue);
    await setInitialFilter(filtersLabel);
  }

  /**
   * Gets range for FROM-TO label with quarter selected
   * @param {*} quarterValue 
   */
  const handleQuartersFilters = (quarterValue) => {
    const filtersLabel = quartersByYear.find(
      (quarter) => quarter.quarter === +quarterValue
    );
    setInitialFilter(filtersLabel);
  }

  /**
   * Request for gets the range of weekends for the combo
   * @api {GET} /calendar/week-endings
   * @returns ["week-endings"]
   */
  const {
    isLoading: isLoadingWeekendings,
    data: weekends 
  } = useGetWeekEndsQuery({
    ...initialFilter 
  }, {
    skip: initialFilter?.from === undefined || skipRequestWeekends
  });

  useEffect(() => {
    if (weekends) {
      const optionsWeekEnds = weekends['week-endings'].map((weekEnd) => ({
        value: formatDate(weekEnd),
        label: formatDate(weekEnd)
      }))
      optionsWeekEnds.unshift({ value: '', label: '' });
      setWeekEndings(optionsWeekEnds);
      props.updFilterData("loadWeekendsAction", isLoadingWeekendings);
    }
    // eslint-disable-next-line
  }, [weekends]);

  useEffect(() => {
    if (selectedWeekEnding) {
      setSkipRequestWeekends(true);
      setInitialFilter((state) => ({
        ...state,
        to: selectedWeekEnding
      }));
    }

    if (!selectedWeekEnding && !selectedQuarter) {
      handleYearsFilters(selectedYear);
      return;
    }
  // eslint-disable-next-line
  }, [selectedWeekEnding]);

  useEffect(() => {
    if (
      initialFilter &&
      Object.keys(initialFilter).length !== 0
    ) {
      props.updFilterData("initialFilterAction", initialFilter);
    }
  // eslint-disable-next-line
  }, [initialFilter]);

  return (
    <div className="points-by-employee__filters">
      <div className="align__filters">
      <div className="margin-align__filters">
          <div>
            <strong>Year</strong>
          </div>
          <div>
            <Select
              selectedValue={selectedYear}
              name="selectedYear"
              onChange={(e) => handleSelect(e)}
              options={years} />
          </div>
        </div>
        <div className="margin-align__filters">
          <div>
            <strong>Quarter</strong>
          </div>
          <div>
            <Select
              selectedValue={
                !selectedYear ||
                selectedYear === ''
                  ? ''
                  : selectedQuarter
              }
              name="selectedQuarter"
              onChange={(e) => handleSelect(e)}
              options={quarters} />
          </div>
        </div>
        <div className="margin-align__filters">
          <div>
            <strong>Week ending</strong>
          </div>
          <div>
            <Select
              selectedValue={
                !selectedYear ||
                selectedYear === ''
                  ? ''
                  : selectedWeekEnding
              }
              name="selectedWeekEnding"
              onChange={(e) => handleSelect(e)}
              options={weekEndings} />
          </div>
        </div>
        <div className="points-by-employee__filters-dates">
          <div>
            <strong>From:</strong>
            <span>{initialFilter?.from}</span>
          </div>
          <div>
            <strong>To:</strong>
            <span>{initialFilter?.to}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
