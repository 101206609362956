import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import Modal from "../../components/Modal";
import Title from "../../components/Title";
import Button from "../../components/Button";
import { faPencil, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import './css/RedeemPoints.scss';
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logout } from "../../components/Logout";
import {
  useDeleteRewardPointsMutation,
  useGetRedeemPointsQuery,
  useGetRewardEmployeesQuery,
} from "../../store/slices/redeemPoints";
import { RedeemDetail } from "./components/RedeemDetail";
import { Toggle } from "../../components/Toggle";
import { Filters } from "./components";
import { getCurrentQuarter, getQuartersByYear } from "../../utils/utils";


const CoE = () => {
  const [optionUsernames, setOptionUserNames] = useState([]);
  const [redeemDetail, setRedeemDetail] = useState({
    employeeIdKey: '',
    employeeName: '',
    redeemDate: '',
    redeemPointsId: ''
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    type: '',
    text: ''
  });
  const { isLoading: isLoadingRewardEmployees, data: employeesList } = useGetRewardEmployeesQuery();
  const [deleteRewardPoints, { isLoading: isDeleting }] = useDeleteRewardPointsMutation();

  /* States for filters */
  const [filters, setFilters] = useState(true);  
  const [searchBar, setSearchBar] = useState(false);
  const [enableTable, setEnableTable] = useState(false);
  const [initialFilter, setInitialFilter] = useState({});
  const [isLoadingWeekendings, setLoadingWeekendings] = useState(false);

  /**
   * Handle default filters when Filters component is disabled
   * @param {*} event 
   */
  const handleFilters = (event) => {
    setFilters(event);

    if (!event) {
      // Return current year
      const defaultYear = new Date().getFullYear();
      // Return current quarter
      const defaultQuarter = getCurrentQuarter();
      // Gets the range of weekends
      const quartersByYear = getQuartersByYear(defaultYear);
      // Filters the weekends of the current quarter
      const filtersLabel = quartersByYear.find(
        (quarter) => quarter.quarter === +defaultQuarter
      );
      setInitialFilter(filtersLabel);
    }
  }

  /**
   * Configure filter states
   * @param {*} action 
   * @param {*} data 
   */
  const updFilterData = (action, data) => {
    if (action === 'initialFilterAction') {
      setInitialFilter(data);
    } else if (action === 'loadWeekendsAction') {
      setLoadingWeekendings(data);
    }
  }

  /**
   * Gets a list of redeem points
   * @api {GET} /redeem-points
   * @returns { "redeemPointsId": 0, "employeeIdKey": 0, "employeeName": "", "redeemDate": "", "redeemPoints": 0 }
   */
  const {
    isLoading,
    data: redeemPointsList
  } = useGetRedeemPointsQuery({
    ...initialFilter
  }, {
    skip:
      initialFilter?.from === undefined ||
      initialFilter?.from === ''
  });

  useEffect(() => {
    if (redeemPointsList) {
      if (redeemPointsList === undefined) {
        setEnableTable(false);
        return;
      }

      setEnableTable(true);
    }
  }, [redeemPointsList]);

  useEffect(() => {
    if (employeesList) {
      setOptionUserNames(employeesList.map((user) => (
        {
          value: user.employeeIdKey,
          label: user.employeeName
        })
      ))
    }
  }, [employeesList]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'employeeName',
        header: "Employee",
        size: 180
      },
      {
        accessorKey: 'redeemDate',
        header: "Redeem Date",
        size: 150
      },
      {
        accessorKey: 'redeemPoints',
        header: "Redeem Points",
        size: 180
      },
    ],
    [],
  );

  const handleModal = async (type, data) => {
    if (type === 'new') {
      setModalSettings({
        type,
        text: 'Add New'
      });
    } else if (type === 'edit') {
      setModalSettings({
        type,
        text: 'Edit Redeem points'
      });
      setRedeemDetail({ ...data });
    }
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setRedeemDetail({
      employeeIdKey: '',
      employeeName: '',
      redeemDate: ''
    });
  }

  const handleDelete = async (redeemId) => {
    try {
      await deleteRewardPoints({ redeemId });
    } catch (error) {
      throw new Error(`Error Deleting ${redeemId}`);
    }
  }

  return ((
    isLoading ||
    isLoadingRewardEmployees ||
    isLoadingWeekendings ||
    isDeleting) ? <Loader /> :
    <>
      <div className="animate__animated animate__fadeIn">
        <Title>
          <Logout title={'Redeem Points'} />
        </Title>
        <div className="content-controls">
          <Button
            block
            icon={faPlusCircle}
            onClick={() => handleModal('new')}
          >New</Button>
          <div className="toggle-control">
            <strong>Filters</strong>
            <Toggle
              onClick={(e) => handleFilters(e)}
              toggled={filters}
            />
          </div>
          <div className="toggle-control">
            <strong>Search bar</strong>
            <Toggle
              onClick={() => setSearchBar(!searchBar)}
              toggled={searchBar}
            />
          </div>
        </div>
        {/* Filters buttons */}
        {
          filters &&
          (<Filters updFilterData={updFilterData} />)
        }
      </div>

      {/* <ScrollerContainer> */}
      <MaterialReactTable
        title="RedeemPoints"
        enableColumnActions={false}
        enableColumnFilters={false}
        // enablePagination={false}
        // enableSorting={false}
        // enableBottomToolbar={false}
        enableStickyHeader
        enableTopToolbar={searchBar}
        enableColumnResizing
        enableGlobalFilterModes
        initialState={{
          showGlobalFilter: true,
        }}
        muiTableContainerProps={{
          sx: {
            height: {
              md: '200px',
              lg: '225px',
              xl: '550px'
            }
          }
        }}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: `  Search`,
          sx: { minWidth: '400px' },
        }}
        columns={columns}
        data={
          !enableTable || 
          !initialFilter.from 
            ? [] 
            : redeemPointsList
        }
        muiTableBodyCellProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyP
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5'
            },
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div className="redeem-points-points__actions">
            <Button onClick={() => handleModal('edit', row.original)}>
              <FontAwesomeIcon icon={faPencil} />
            </Button>
            <Button id="delete" onClick={() => handleDelete(row.original.redeemPointsId)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Actions',
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
            size: 120,
          },
        }}
      />
      {/* </ScrollerContainer> */}

      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Title>{modalSettings?.text}</Title>
        <RedeemDetail
          optionUsernames={optionUsernames}
          redeemDetail={redeemDetail}
          handleCloseModal={handleCloseModal}
          options={modalSettings}
        />
      </Modal>
    </>

  );
};

export default CoE;