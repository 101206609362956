import React, { useState, useMemo, useEffect } from "react";
import MaterialReactTable from 'material-react-table';
import Title from "../../components/Title";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import "./css/PointsByEmployee.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Logout } from "../../components/Logout";
import { Toggle } from "../../components/Toggle";
import Loader from "../../components/Loader";
import { DetailsPointsEmpoyee, ExcelExport, Filters } from "./components";
import { 
  useGetAllEmployeesPointsQuery, 
  useGetPointsConversionFactorQuery 
} from "../../store/slices/pointsByEmployee/apis/pointsByEmployeeApi";
import { getCurrentQuarter, getQuartersByYear } from "../../utils/utils";

const EmployeesPoints = () => {
  const [openModal, setOpenModal] = useState(false);
  const [employeeData, setEmployeeData] = useState();

  /* States for filters */
  const [filters, setFilters] = useState(true);
  const [searchBar, setSearchBar] = useState(false);
  const [enableTable, setEnableTable] = useState(false);
  const [initialFilter, setInitialFilter] = useState({});
  const [isLoadingWeekendings, setLoadingWeekendings] = useState(false);

  /**
   * Handle default filters when Filters component is disabled
   * @param {*} event 
   */
  const handleFilters = (event) => {
    setFilters(event);

    if (!event) {
      // Return current year
      const defaultYear = new Date().getFullYear();
      // Return current quarter
      const defaultQuarter = getCurrentQuarter();
      // Gets the range of weekends
      const quartersByYear = getQuartersByYear(defaultYear);
      // Filters the weekends of the current quarter
      const filtersLabel = quartersByYear.find(
        (quarter) => quarter.quarter === +defaultQuarter
      );
      setInitialFilter(filtersLabel);
    }
  }

  /**
   * Configure filter states
   * @param {*} action 
   * @param {*} data 
   */
  const updFilterData = (action, data) => {
    if (action === 'initialFilterAction') {
      setInitialFilter(data);
    } else if (action === 'loadWeekendsAction') {
      setLoadingWeekendings(data);
    }
  }

  const {
    isLoading,
    data: pointsEarned
  } = useGetAllEmployeesPointsQuery({
    ...initialFilter
  }, {
    skip:
      initialFilter?.from === undefined ||
      initialFilter?.from === ''
  });

  useEffect(() => {
    if (pointsEarned) {
      if (pointsEarned === undefined) {
        setEnableTable(false);
        return;
      }

      setEnableTable(true);
    }
  }, [pointsEarned]);

  const {
    isLoading: isLoadingConversion, 
    data: pointsConversionFactor 
  } = useGetPointsConversionFactorQuery();

  const columns = useMemo(() => [
    {
      accessorKey: "employeName",
      header: "Employee",
      size: 110, //large column
    },
    {
      accessorKey: 'earnedPoints',
      header: "Earned",
      size: 70, //medium column
    },
    {
      //accessorFn function that combines multiple data together
      accessorKey: 'redeemedPoints',
      header: "Redeemed",
      size: 80, //medium column
    },
    {
      //accessorFn used to access nested data, though you could just use dot notation in an accessorKey
      accessorKey: 'currentBalance',
      header: 'Current Balance',
      size: 90, //medium column
    },
  ], []);

  return ((
    isLoading ||
    isLoadingWeekendings ||
    isLoadingConversion) ? <Loader /> :
    <>
      <div className="animate__animated animate__fadeIn">
        <Title>
          <Logout title={'Employee Points'} />
        </Title>

        <div className="content-controls">
          <div className="toggle-control">
            <strong>Filters</strong>
            <Toggle
              onClick={(e) => handleFilters(e)}
              toggled={filters} />
          </div>
          <div className="toggle-control">
            <strong>Search bar</strong>
            <Toggle
              onClick={() => setSearchBar(!searchBar)}
              toggled={searchBar} />
          </div>
          {
            pointsConversionFactor && pointsEarned?.length > 0 && (
              <div className="toggle-control">
                <strong>Export as Excel File</strong>
                <div className="export-action">
                  <ExcelExport
                    data={pointsEarned}
                    filters={initialFilter}
                    conversionFactor={pointsConversionFactor.pointsConversionFactor}
                  />
                </div>
              </div>
            )
          }
        </div>
        {
          filters &&
          (<Filters updFilterData={updFilterData} />)
        }
      </div>

      {/* <ScrollerContainer> */}
      <MaterialReactTable
        title="Employees"
        enableColumnResizing
        enableColumnActions={false}
        // enableColumnFilters={true}
        enableStickyHeader
        muiTableContainerProps={{
          sx: {
            height: {
              lg: '225px',
              xl: '550px'
            }
          }
        }}
        enableGlobalFilterModes
        initialState={{
          showGlobalFilter: true,
        }}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: `  Search`,
          sx: {
            minWidth: '400px',
            fieldset: {
              border: 'none !important',
              outline: 'none !important'
            }
          }
        }}
        // enablePagination={false}
        // enableSorting={false}
        // enableBottomToolbar={false}
        enableTopToolbar={searchBar}
        columns={columns}
        data={
          !enableTable ||
          (!initialFilter?.from &&
          !initialFilter?.to)
            ? []
            : pointsEarned
        }
        muiTableBodyCellProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium',
            padding: '.5rem'
          },
        }}
        // muiTableBodyP\
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5'
            },
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Button id="action-edit" onClick={() => {
            setOpenModal(true);
            setEmployeeData(row.original);
            // handleModal('edit', row.original)
          }}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Details',
            size: 60,
          },
        }}
      />
      {/* </ScrollerContainer> */}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div>
          {employeeData &&
            <>
              <Title>{employeeData.employeName}</Title>
              <DetailsPointsEmpoyee
                employeId={employeeData.employeeIdKey}
                filters={initialFilter}
              />
            </>
          }
        </div>
      </Modal>
    </>
  )
};

export default EmployeesPoints;
